import { render, staticRenderFns } from "./released-shipments.vue?vue&type=template&id=a83a8bbe&scoped=true&"
import script from "./released-shipments.vue?vue&type=script&lang=js&"
export * from "./released-shipments.vue?vue&type=script&lang=js&"
import style1 from "./released-shipments.vue?vue&type=style&index=1&id=a83a8bbe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a83a8bbe",
  null
  
)

export default component.exports